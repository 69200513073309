const useLoadLanguage = () => {
  const loadLanguage = async ({ locale }: { locale: string }) => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/v1/web/translations/${locale}`,
    );
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return await response.json();
  };
  return { loadLanguage };
};
export { useLoadLanguage };
