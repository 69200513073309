import {
  Col,
  DatePicker,
  Form,
  List,
  Row,
  Space,
  Typography,
  Button,
  Dropdown,
  Select,
  Modal,
} from "antd";
import { CalendarOutlined, EditOutlined } from "@ant-design/icons";
import dayjs, { Dayjs } from "dayjs";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import {
  ShippingSlot,
  useAllowedShippingConfig,
} from "../../Networking/useAllowedShippingConfig";
import { useMe } from "../../AppSetup/MeContext";
import ShippingProductBanner from "../../Products/ShippingProductBanner";
import { useNavigate } from "react-router-dom";
import useCart from "../../Hooks/useCart";
import { useSettings } from "../../Networking/useSettings";
import { useDiscount } from "../../Hooks/useDiscount";
import { Trans, useTranslation } from "react-i18next";
import useUATracker from "../../Networking/Metrics/useUATracker";
import { DeliveryDateSelected } from "../../Networking/Metrics/UserActions/Shop";
import { ShippingInfo } from "../../common/shippingInfo";
import { capitalize } from "../../Lib/caseUtils";
import { useUnlimitedMembership } from "../../Hooks/useUnlimitedMembership";

const SelectShippingDate = () => {
  const me = useMe();
  const navigate = useNavigate();
  const {
    subsEnabled,
    improvedShippingFlow,
    defaultSubscriptionPeriodInWeeks,
    unlimitedEnabled,
    country,
  } = useSettings();
  const { t } = useTranslation();

  const {
    shippingInfoForm,
    selectedSub,
    shippingDate,
    setShippingDate,
    setShippingNotes,
    setShippingTimeSlot,
    shippingTimeSlot,
    primaryButtonClick,
    setDisablePrimaryButton,
  } = useCart();

  const formattedDateForAlert = shippingDate
    ?.add(1, "day")
    .format("DD/MM/YYYY");

  primaryButtonClick.subscribe(async () => {
    const shippingInfo =
      (await shippingInfoForm.validateFields()) as ShippingInfo;
    setShippingDate(shippingInfo.date);
    setShippingNotes(shippingInfo.notes || "");
  });

  useEffect(() => {
    if (subsEnabled && !selectedSub) {
      navigate("/shop/choose-subscription");
    }
  }, [selectedSub, subsEnabled, navigate]);

  shippingInfoForm.setFieldsValue({
    firstName: me?.firstName,
    lastName: me?.lastName,
  });

  const params = me.isSuperUser
    ? {}
    : {
        minOffset: subsEnabled ? 5 : undefined,
      };

  const shippingConfig = useAllowedShippingConfig(params);

  const discount = useDiscount();

  const [datePickerDisplayed, displayDatePicker] = useState(false);
  const [dateSelected, setDateSelected] = useState(!!shippingDate);
  const [shippingOptions, setShippingOptions] = useState<
    string[] | undefined
  >();

  const { trackUserAction } = useUATracker();

  useEffect(() => {
    if (shippingConfig && shippingDate) {
      setShippingOptions(shippingConfig[shippingDate.format("YYYY-MM-DD")]);
    }
    if (shippingOptions && shippingOptions.length > 1 && !shippingTimeSlot) {
      setDisablePrimaryButton(true);
    } else {
      setDisablePrimaryButton(false);
    }
  }, [
    shippingConfig,
    shippingDate,
    shippingTimeSlot,
    shippingOptions,
    setDisablePrimaryButton,
  ]);

  const unlimitedMembership = useUnlimitedMembership();

  const carrierNotes = improvedShippingFlow
    ? [
        "Il pacco viene consegnato con corriere dalle 8:30 alle 18. Non è possibile scegliere la fascia oraria.",
        "Il corriere potrebbe consegnare la box fino a un giorno dopo, entro 48 ore dalla spedizione. Il ghiaccio secco manterrà i piatti surgelati.",
        "Una volta arrivato, il pacco puó rimanere fuori dal congelatore fino a sera senza scongelarsi.",
      ]
    : [
        "La data è indicativa perché non è detto che il corriere ti consegni il pacco esattamente quel giorno. Potrebbe arrivare il giorno successivo.",
        "Il pacco viene consegnato in un orario che va dalle 8.30 alle 18.",
        "Il pacco può rimanere fuori dal congelatore fino alla sera del giorno di consegna perché il ghiaccio secco all'interno garantisce la corretta conservazione.",
        "È importante che il pacco ti arrivi al più 48h dopo la spedizione, quindi ti chiediamo di non posticipare la consegna o cambiare destinazione.",
      ];

  let intro = (
    <Space direction={"vertical"} style={{ marginBottom: 16 }}>
      <p className={styles.bodySmall}>
        {t(
          "Spediamo nella tua zona con corriere espresso! Scegli la data indicativa in cui ricevere il tuo ordine.",
        )}
      </p>
    </Space>
  );
  if (subsEnabled) {
    intro = (
      <>
        <div className={styles.text}>
          <p>
            {t(
              "Spediamo con corriere espresso! Scegli la data in cui ricevere la tua Box.",
            )}
          </p>
        </div>
        <div className={styles.infoPack}>
          <div className={styles.recapLine}>
            <div>
              <Trans i18nKey={"Box da"} boxSize={selectedSub?.boxSize}>
                Box da {selectedSub?.boxSize} piatti
              </Trans>
            </div>
            <div className={styles.recapBold}>
              {discount?.amount && (
                <>
                  <span
                    style={{
                      color: "#8F959E",
                      textDecoration: "line-through",
                      fontWeight: "500",
                    }}
                  >
                    {(selectedSub?.price || 1) / 100}€
                  </span>
                  &nbsp;&nbsp;
                </>
              )}
              {(
                (selectedSub?.price || 1) / 100 -
                (discount?.amount || 0)
              ).toFixed(2)}
              € &nbsp;
              {!me.subscriptionProfile && (
                <img
                  src="/icons/edit_small.svg"
                  alt="edit"
                  className={styles.editIcon}
                  onClick={() => navigate("/shop/choose-subscription")}
                />
              )}
            </div>
          </div>
          {discount?.amount && (
            <div className={styles.discountNote}>
              il primo ordine, poi {(selectedSub?.price || 1) / 100}€
            </div>
          )}
          <div className={styles.recapLine}>
            <div>{t("Frequenza")}</div>
            <div className={styles.recapBold}>
              Ogni {selectedSub?.period.count} {selectedSub?.period.unit} &nbsp;
              {!me.subscriptionProfile && (
                <img
                  src="/icons/edit_small.svg"
                  alt="edit"
                  className={styles.editIcon}
                  onClick={() => navigate("/shop/choose-subscription")}
                />
              )}
            </div>
          </div>
          {!!me.subscriptionProfile && (
            <Button
              className={styles.button}
              style={{ marginTop: 8, backgroundColor: "#eeeff4" }}
              type={"text"}
              onClick={() => {
                navigate("/shop/choose-subscription");
              }}
            >
              Modifica
              <EditOutlined className={styles.buttonIcon} />
            </Button>
          )}
        </div>
      </>
    );
  }

  const defaultSubscriptionPeriodNotes = defaultSubscriptionPeriodInWeeks ? (
    <div className={styles.frequencyDropdown}>
      <Dropdown disabled={true}>
        <Space>
          <span>
            <b>{t("Frequenza")}:</b> {t("ogni")}{" "}
            {defaultSubscriptionPeriodInWeeks} {t("settimane")}
          </span>
        </Space>
      </Dropdown>
      {!me.subscriptionProfile && (
        <div className={styles.frequencyNote}>
          <img src={"/icons/exit_green.svg"} alt={"easy exit"} />
          {t(
            "Prima Box senza vincoli! Personalizza il tuo abbonamento e cambia frequenza in pochi click dopo il primo ordine. La cancellazione è gratuita.",
          )}
        </div>
      )}
    </div>
  ) : null;

  const note = (
    <div className={styles.bodySmall}>
      <p style={{ fontWeight: 800, fontSize: 15 }}>
        {t("Informazioni importanti")}
      </p>
      <List
        dataSource={carrierNotes}
        renderItem={(item) => (
          <List.Item key={item}>
            <Typography.Text mark>●</Typography.Text> {t(item)}
          </List.Item>
        )}
      />
    </div>
  );

  const setDate = (date: Dayjs) => {
    setDateSelected(true);
    setShippingDate(date);
    setShippingOptions(shippingConfig?.[date.format("YYYY-MM-DD")]);
    trackUserAction(new DeliveryDateSelected());
  };

  const onDateClick = (date: Dayjs | null) => {
    if (date) {
      if (
        me.isUnlimitedMember &&
        unlimitedMembership &&
        date.isAfter(dayjs(unlimitedMembership.validUntil).add(1, "day"))
      ) {
        Modal.confirm({
          title: t("Attenzione"),
          content: t(
            "La data selezionata è oltre la validità del tuo abbonamento. Se l'abbonamento non sarà rinnovato," +
              " l'ordine verrà annullato. Sei sicuro di voler procedere?",
          ),
          okText: t("Ok"),
          cancelText: t("Annulla"),
          onOk: () => {
            setDate(date);
          },
        });
        return;
      } else {
        setDate(date);
      }
    } else {
      setDateSelected(false);
    }
  };

  const FormSpedizione = (
    <>
      <>
        <Form
          form={shippingInfoForm}
          layout="vertical"
          style={{ marginTop: 24 }}
        >
          <Form.Item
            label={
              improvedShippingFlow
                ? t("Prossima consegna")
                : t("Giorno previsto per la prossima consegna")
            }
            className={styles.formInput}
            required
          >
            <Row gutter={8}>
              <Col style={{ flexGrow: 1 }}>
                {improvedShippingFlow &&
                  !dateSelected &&
                  !datePickerDisplayed && (
                    <Button
                      className={styles.button + " " + styles.primary}
                      type={"text"}
                      onClick={() => {
                        displayDatePicker(true);
                      }}
                    >
                      {t("Seleziona data di consegna")}
                      <CalendarOutlined className={styles.buttonIcon} />
                    </Button>
                  )}

                <Form.Item
                  name={"date"}
                  className={styles.formInput}
                  style={{
                    display:
                      improvedShippingFlow &&
                      !datePickerDisplayed &&
                      !dateSelected
                        ? "none"
                        : "unset",
                  }}
                  initialValue={
                    me.subscriptionProfile?.nextDeliveryDate
                      ? dayjs(me.subscriptionProfile?.nextDeliveryDate)
                      : undefined
                  }
                  rules={[
                    { required: true, message: t("Seleziona una data!") },
                  ]}
                >
                  <DatePicker
                    format={"DD/MM/YYYY"}
                    placeholder={t("Seleziona data")}
                    className={styles.datePickerInput}
                    popupClassName={styles.dropdown}
                    onChange={onDateClick}
                    open={
                      improvedShippingFlow &&
                      datePickerDisplayed &&
                      !dateSelected
                        ? true
                        : undefined
                    }
                    cellRender={(date: Dayjs | string | number, info) => {
                      if (info.type !== "date") return info.originNode;
                      if (
                        typeof date === "string" ||
                        typeof date === "number"
                      ) {
                        return;
                      }

                      const fdate = date.format("YYYY-MM-DD");
                      const opts = shippingConfig
                        ? shippingConfig[fdate]
                        : ["tuttoIlGiorno"];
                      let style = {};
                      if (opts && opts.length > 0) {
                        style = { backgroundColor: "#d4e4cd" };
                        if (
                          opts.includes(ShippingSlot.pomeriggio) ||
                          opts.includes(ShippingSlot.mattina)
                        ) {
                          style = { backgroundColor: "#9ac18b" };
                        }
                      }

                      return (
                        <div className="ant-picker-cell-inner" style={style}>
                          {date.date()}
                        </div>
                      );
                    }}
                    disabledDate={(date: Dayjs) => {
                      const fdate = date.format("YYYY-MM-DD");
                      return (
                        !me.isSuperUser &&
                        !(
                          !!shippingConfig &&
                          fdate in shippingConfig &&
                          shippingConfig[fdate].length > 0
                        )
                      );
                    }}
                  />
                </Form.Item>
              </Col>
              <Col
                hidden={!shippingOptions || shippingOptions.length <= 1}
                style={{ flexGrow: 1 }}
              >
                <Form.Item>
                  <Select
                    onChange={(value) => {
                      setShippingTimeSlot(value);
                    }}
                    options={shippingOptions?.map((opt) => ({
                      label: t(capitalize(opt)),
                      value: opt,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>

          {dateSelected && formattedDateForAlert && country !== "Germany" && (
            <div className={styles.noteWithIcon}>
              <img src={"/icons/alert.svg"} alt={"attention"} />
              <Trans
                i18nKey={"shippingAlert"}
                values={{ shippingDate: formattedDateForAlert }}
              >
                La consegna potrebbe avvenire anche in data {{ shippingDate }}
              </Trans>
            </div>
          )}

          <Form.Item
            style={{ marginTop: 16, marginBottom: 16 }}
            name={"notes"}
            label={t("Note per la spedizione")}
            extra={
              !improvedShippingFlow &&
              t(
                "Se hai esigenze particolari per la consegna inseriscile qui. Non indicare fasce orarie perché questo corriere non può rispettarle.",
              )
            }
          >
            <TextArea />
          </Form.Item>
        </Form>
      </>
    </>
  );

  return (
    <Row justify="center">
      <Col span={8} xs={24} md={12} xl={8} style={{ padding: "0 24px" }}>
        {(subsEnabled || unlimitedEnabled) && (
          <div className={styles.titleCentered}>{t("Spedizione")}</div>
        )}
        {!improvedShippingFlow && intro}
        {FormSpedizione}
        {defaultSubscriptionPeriodNotes}
        {note}
        <div style={{ marginLeft: -16, marginRight: -16, marginBottom: 24 }}>
          <ShippingProductBanner />
        </div>
      </Col>
    </Row>
  );
};

export default SelectShippingDate;
