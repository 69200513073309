import Intercom from "@intercom/messenger-js-sdk";
import { useMe } from "../AppSetup/MeContext";
import CryptoJS from "crypto-js";

const IntercomChat = () => {
  const me = useMe();

  const secretKey = process.env.REACT_APP_INTERCOM_SECRET_KEY!;
  const userIdentifier = me.email;

  const user_hash = CryptoJS.HmacSHA256(userIdentifier, secretKey).toString(
    CryptoJS.enc.Hex,
  );

  Intercom({
    app_id: "l3i9op4u",
    name: me.firstName,
    email: me.email,
    user_hash,
  });
  return <></>;
};

export default IntercomChat;
