import { Product } from "../common/product";
import { useSettings } from "../Networking/useSettings";

interface ProductImageTag {
  name: string;
  position: "tl" | "tr" | "bl" | "br";
  size?: "sm" | "md";
  type: "producedBy" | "other";
}

function parseImageTag(tag: string): ProductImageTag | null {
  const regex =
    /^imagetag:(?<name>[^:]+):(?<position>tl|tr|bl|br)(:(?<size>sm|md))?$/;
  const match = tag.match(regex);

  if (!match?.groups) {
    return null;
  }

  return {
    ...match.groups,
    type: match.groups.position === "bl" ? "producedBy" : "other",
  } as unknown as ProductImageTag;
}

const useImageTags = (product: Product) => {
  const { showProductImageTags } = useSettings();
  return product.tags
    .map(parseImageTag)
    .filter((tag) => tag !== null)
    .filter(
      (tag) => showProductImageTags || tag?.type !== "producedBy",
    ) as ProductImageTag[];
};

export { useImageTags };

export type { ProductImageTag };
