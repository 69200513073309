import { useEndpoint } from "../Networking/common";
import { Me, StripeMembershipStatus, WaitingListStatus } from "../common/me";
import { useLogin } from "./LoginContext";
import { Spin } from "antd";
import MeContext from "./MeContext";
import { Main } from "../Main";
import { useLocation, useNavigate } from "react-router-dom";
import useFbPixel from "../Networking/useFbPixel";
import { useEffect, useState } from "react";
import { useSettings } from "../Networking/useSettings";
import { DynamicPopupElement } from "../DynamicPopup/DynamicPopup";
import IntercomChat from "../Chat/IntercomChat";

const AppSetupMe = () => {
  const { attemptRelogin } = useLogin();

  const { sendEvent } = useFbPixel();
  const location = useLocation();
  const navigate = useNavigate();

  const { unlimited, waitingListId, dynamicPopUp, intercomChatEnabled } =
    useSettings();

  const [isSuperUser, setIsSuperUser] = useState(false);

  const { data: me } = useEndpoint<Me>("/me", {}, (err) => {
    if (err.response.status === 401) {
      attemptRelogin();
    }
  });

  useEffect(() => {
    if (me?.pendingCheckoutSession && !location.pathname.includes("stripe")) {
      navigate("/shop/stripe/checkout");
    }
  }, [me, navigate, location]);

  useEffect(() => {
    sendEvent("PageView");
  }, [sendEvent, location]);

  if (!me) {
    return <Spin />;
  }

  const isUnlimitedMember = me?.stripeMemberships.some(
    (membership) =>
      membership.membershipId === unlimited?.membershipId &&
      [StripeMembershipStatus.Active, StripeMembershipStatus.Trialing].includes(
        membership.status,
      ),
  );

  const isWaitingListMember = me?.waitingLists.some(
    (waitingList) =>
      waitingList.waitingListId === waitingListId &&
      waitingList.status === WaitingListStatus.Waiting,
  );

  return (
    <MeContext.Provider
      value={{
        me,
        isSuperUser,
        setIsSuperUser,
        isUnlimitedMember,
        isWaitingListMember,
      }}
    >
      {dynamicPopUp && <DynamicPopupElement popup={dynamicPopUp} />}
      {intercomChatEnabled && <IntercomChat />}
      <Main />
    </MeContext.Provider>
  );
};

export default AppSetupMe;
