import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  QRCode,
  Row,
  Space,
  Spin,
  Tooltip,
} from "antd";
import React, { useState } from "react";
import AddressModal from "../AddressModal/AddressModal";
import { useNavigate } from "react-router-dom";
import InvoiceForm from "./InvoiceForm";
import Logo from "../Home/HomeComponents/Logo";
import styles from "./styles.module.css";
import homeStyles from "../Home/styles.module.css";
import {
  ProfileOutlined,
  LogoutOutlined,
  NotificationOutlined,
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import useUATracker from "../Networking/Metrics/useUATracker";
import { UserLogoutSuccessful } from "../Networking/Metrics/UserActions/Login";
import { useLogin } from "../AppSetup/LoginContext";
import { useMe } from "../AppSetup/MeContext";
import { ContactExpertCTASelected } from "../Networking/Metrics/UserActions/common";
import { useEditableSubscription } from "../Hooks/useEditableSubscription";
import dayjs, { Dayjs } from "dayjs";
import useApiClient from "../Networking/useApiClient";
import {
  SubscriptionProfileCancelled,
  SubscriptionProfilePaused,
} from "../Networking/Metrics/UserActions/Profile";
import { useSettings } from "../Networking/useSettings";
import { useOpenInNewTab } from "../Hooks/useRedirect";
import { useTranslation } from "react-i18next";
import { MarketingConsentModal } from "./MarketingConsentModal";
import { useUnlimitedMembership } from "../Hooks/useUnlimitedMembership";

const SubConfirmModal = ({
  open,
  onOk,
  onCancel,
  onPostpone,
}: {
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
  onPostpone: () => void;
}) => {
  const { trackUserAction } = useUATracker();
  const { cancelSubscription } = useApiClient();

  const [form] = Form.useForm();

  const onFinish = (values: { reason: string }) => {
    trackUserAction(
      new SubscriptionProfileCancelled({ reason: values.reason }),
    );
    void cancelSubscription();
    onOk();
  };

  const onCancelClick = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      title="Annulla abbonamento"
      open={open}
      onCancel={onCancelClick}
      footer={
        <>
          <Button onClick={onPostpone}>No, voglio posticiparlo</Button>
          <Button onClick={form.submit} type={"primary"}>
            Conferma
          </Button>
        </>
      }
    >
      <Form form={form} layout={"vertical"} onFinish={onFinish}>
        <Form.Item
          name="reason"
          label={"Perché vuoi annullare il tuo abbonamento?"}
          rules={[{ required: true, message: "Inserisci un motivo" }]}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const UnlimitedEditModal = ({
  open,
  onPause,
  onCancel,
  onDelete,
  membershipId,
}: {
  open: boolean;
  onPause: () => void;
  onCancel: () => void;
  onDelete: () => void;
  membershipId: string;
}) => {
  const { unlimitedMinimumPauseDurationInDays } = useSettings();
  const { pauseMembership } = useApiClient();
  const { t } = useTranslation();
  const { trackUserAction } = useUATracker();
  const [form] = Form.useForm();
  const [deleteForm] = Form.useForm();
  const [openDelete, setOpenDelete] = useState(false);

  const unlimitedMembership = useUnlimitedMembership();

  const validUntilDate = dayjs(unlimitedMembership.validUntil);

  const onPauseUnlimited = (values: { until: Dayjs }) => {
    void pauseMembership({
      stripeSubscriptionId: unlimitedMembership.stripeSubscriptionId,
      until: values.until,
    });
    trackUserAction(
      new SubscriptionProfilePaused({
        resumeSubscriptionDate: values.until,
      }),
    );
    onPause();
  };

  const onDeleteUnlimited = (values: { churnReason: string }) => {
    void pauseMembership({
      stripeSubscriptionId: unlimitedMembership.stripeSubscriptionId,
      churnReason: values.churnReason,
    });
    trackUserAction(
      new SubscriptionProfileCancelled({
        reason: values.churnReason,
      }),
    );
    setOpenDelete(false);
    onDelete();
  };

  const onCancelClick = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <>
      <Modal
        title={t("Modifica abbonamento")}
        open={open}
        onCancel={onCancelClick}
        footer={
          <div className={styles.smallModalFooter}>
            <Button
              className={styles.button}
              style={{ marginBottom: 8, background: "transparent" }}
              onClick={() => setOpenDelete(true)}
            >
              {t("Cancella definitivamente")}
            </Button>
            <Button
              className={styles.button}
              style={{ marginBottom: 8, marginInlineStart: 0 }}
              onClick={form.submit}
              type={"primary"}
            >
              {t("Metti in pausa")}
            </Button>
          </div>
        }
      >
        <Form
          form={form}
          layout={"vertical"}
          onFinish={onPauseUnlimited}
          style={{ marginTop: 16, marginBottom: 40 }}
        >
          <Form.Item
            name="until"
            label={t("Fino a quando vuoi mettere in pausa l'abbonamento?")}
            rules={[{ required: true, message: t("Inserisci una data") }]}
          >
            <DatePicker
              format={"DD/MM/YYYY"}
              placeholder={t("Seleziona data")}
              popupClassName={styles.dropdown}
              disabledDate={(date) => {
                const closestValidDate = unlimitedMinimumPauseDurationInDays
                  ? validUntilDate.add(
                      unlimitedMinimumPauseDurationInDays,
                      "day",
                    )
                  : validUntilDate.add(1, "month");
                return date.isBefore(closestValidDate, "day");
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={t("Cancellazione abbonamento")}
        open={openDelete}
        onCancel={() => setOpenDelete(false)}
        footer={
          <div className={styles.smallModalFooter}>
            <Button
              className={styles.button}
              style={{ marginBottom: 8, background: "transparent" }}
              onClick={() => setOpenDelete(false)}
            >
              {t("Torna indietro")}
            </Button>
            <Button
              className={styles.button}
              style={{ marginBottom: 8, marginInlineStart: 0 }}
              onClick={() => {
                deleteForm.submit();
              }}
              type={"primary"}
            >
              {t("Cancella abbonamento")}
            </Button>
          </div>
        }
      >
        <Form
          form={deleteForm}
          layout={"vertical"}
          onFinish={onDeleteUnlimited}
          style={{ marginTop: 16, marginBottom: 40 }}
        >
          <Form.Item
            name="churnReason"
            label={t("Perchè vuoi disattivare l'abbonamento?")}
            rules={[
              {
                required: true,
                message: t("Inserisci motivazione"),
              },
            ]}
          >
            <Input placeholder={t("Inserisci motivazione")} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

const Profile = () => {
  const [showModal, setShowModal] = useState(false);

  const { generateAppUrl } = useApiClient();

  const navigate = useNavigate();

  const { logout } = useLogin();

  const { trackUserAction, sendRecentEvents } = useUATracker();

  const [subCancelModalOpen, setSubCancelModalOpen] = useState(false);

  const [unlimitedEditModalOpen, setUnlimitedEditModalOpen] = useState(false);

  const [loadingUrl, setLoadingUrl] = useState(false);

  const [appLoginUrl, setAppLoginUrl] = useState<string>();

  const {
    subsEnabled,
    supportUrl,
    unlimitedEnabled,
    unlimitedLimitAddressChanges,
    appQrLoginEnabled,
  } = useSettings();
  const me = useMe();

  const unlimitedMembership = useUnlimitedMembership();

  const { canEditSubscription } = useEditableSubscription();
  const { t } = useTranslation();

  const [marketingConsentModalOpen, setMarketingConsentModalOpen] =
    useState(false);

  const fetchAppLoginUrl = async () => {
    setLoadingUrl(true);
    const url = await generateAppUrl();
    setAppLoginUrl(url);
    setLoadingUrl(false);
  };

  const renderLoginQR = () => {
    if (!me.isUnlimitedMember) {
      return (
        <div>
          <InfoCircleOutlined style={{ marginRight: 4 }} />
          {t("Potrai scaricare l'app solo dopo aver attivato l'abbonamento.")}
        </div>
      );
    }
    if (loadingUrl) {
      return <Spin />;
    }
    if (appLoginUrl === undefined) {
      return (
        <div>
          <div>
            <WarningOutlined style={{ marginRight: 4 }} />
            {t(
              "Ogni volta che generi un QR code, perdi l'accesso sui dispositivi dove hai già effettuato il login.",
            )}
          </div>
          <Button
            type={"primary"}
            onClick={fetchAppLoginUrl}
            style={{ marginTop: 16 }}
          >
            {t("Genera QR per l'app")}
          </Button>
        </div>
      );
    }

    return (
      <>
        <QRCode
          value={appLoginUrl}
          style={{ width: "100%", maxWidth: 300, margin: "auto" }}
        />
        <span style={{ textAlign: "center", display: "block" }}>
          {t("Scansiona il QR per scaricare l'app")}
          <br />
          {`Per test, l'auth code lo puoi copiare da qui: ${appLoginUrl}`}
        </span>
      </>
    );
  };

  const onPostponeAction = () => {
    if (canEditSubscription) {
      navigate("/shop/edit-subscription-date");
    } else {
      openInNewTab(
        `https://6jwrfvwe6gp.typeform.com/to/uTpcyoVk#record_id=${me.hubspotRecordId}&email=${me.email}`,
      );
    }
  };
  const openInNewTab = useOpenInNewTab();

  const tooltipMessage =
    "Non puoi modificare la data di spedizione adesso, perché il tuo ordine è in preparazione." +
    " Se hai bisogno di assistenza, contattaci su WhatsApp o via email.";

  const _logout = () => {
    trackUserAction(new UserLogoutSuccessful());
    void logout();
  };

  const toggleModal = () => setShowModal(!showModal);

  // Prevent scrolling if modal is opened
  if (showModal) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "unset";
  }

  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <SubConfirmModal
        open={subCancelModalOpen}
        onOk={() => {
          setSubCancelModalOpen(false);
        }}
        onCancel={() => {
          setSubCancelModalOpen(false);
        }}
        onPostpone={() => {
          setSubCancelModalOpen(false);
          onPostponeAction();
        }}
      />

      <Row style={{ marginTop: 16, marginLeft: 16, marginRight: 16 }}>
        <Logo small />
        <div className={homeStyles.headerTitle}>
          <h1>{t("Profilo")}</h1>
        </div>
      </Row>
      <Row justify="center" style={{ marginTop: 16 }}>
        <Col xs={24} md={12} xl={8}>
          {me.subscriptionProfile &&
            (me.subscriptionProfile.enabled ? (
              <Col className={styles.infoContainer}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                  }}
                >
                  <h1 className={styles.infoTitle}>Abbonamento</h1>
                  <Tooltip title={canEditSubscription ? "" : tooltipMessage}>
                    <b
                      className={styles.subEditBtn}
                      onClick={() => {
                        if (!canEditSubscription) {
                          return;
                        }
                        navigate("/shop/edit-subscription");
                      }}
                    >
                      Modifica
                      <img
                        src="/icons/edit_small.svg"
                        alt="edit"
                        className={styles.editIcon}
                      />
                    </b>
                  </Tooltip>
                </div>
                <div className={styles.infoPack}>
                  <h4 className={styles.infoLabel}>{t("Prossimo rinnovo")}</h4>
                  <span className={styles.inputValue}>
                    {dayjs(me.subscriptionProfile.validUntil).format(
                      "DD / MM / YYYY",
                    )}
                  </span>
                </div>
                <div className={styles.infoPack}>
                  <h4 className={styles.infoLabel}>{t("Prossima consegna")}</h4>
                  <span className={styles.inputValue}>
                    Consegna prevista il{" "}
                    {canEditSubscription
                      ? dayjs(me.subscriptionProfile.nextDeliveryDate).format(
                          "DD / MM / YYYY",
                        )
                      : me.subscriptionProfile.latestOutboundOrder
                            ?.expectedShippingDate
                        ? dayjs(
                            me.subscriptionProfile.latestOutboundOrder
                              ?.expectedShippingDate,
                          ).format("DD / MM / YYYY")
                        : "Riprova fra poco, stiamo lavorando alla tua Box!"}
                  </span>
                </div>
                <div className={styles.infoPack}>
                  <h4 className={styles.infoLabel}>Dimensione Box</h4>
                  <span className={styles.inputValue}>
                    Box da {me.subscriptionProfile.boxSize}
                  </span>
                </div>
                <div className={styles.infoPack}>
                  <h4 className={styles.infoLabel}>Frequenza</h4>
                  <span className={styles.inputValue}>
                    Ogni {me.subscriptionProfile.frequencyInWeeks} {"settimane"}
                  </span>
                </div>
                <div style={{ marginTop: 24 }}>
                  <Button
                    className={styles.button}
                    type={"text"}
                    onClick={() => setSubCancelModalOpen(true)}
                  >
                    {t("Annulla abbonamento")}
                    <DeleteOutlined className={styles.buttonIcon} />
                  </Button>
                </div>
              </Col>
            ) : (
              <Col>
                <p style={{ color: "red", fontWeight: "bold" }}>
                  Abbonamento disattivato
                </p>
              </Col>
            ))}

          {appQrLoginEnabled && (
            <Col className={styles.infoContainer}>
              <Logo small />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                  marginBottom: 16,
                }}
              >
                <h1 className={styles.infoTitle}>{t("Scarica l'app")}</h1>
              </div>
              {renderLoginQR()}
            </Col>
          )}

          {unlimitedMembership ? (
            <>
              <UnlimitedEditModal
                open={unlimitedEditModalOpen}
                onPause={() => {
                  setUnlimitedEditModalOpen(false);
                }}
                onCancel={() => {
                  setUnlimitedEditModalOpen(false);
                }}
                onDelete={() => {
                  setUnlimitedEditModalOpen(false);
                }}
                membershipId={unlimitedMembership.membershipId}
              />
              <Col className={styles.infoContainer}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                  }}
                >
                  <h1 className={styles.infoTitle}>{t("Abbonamento")}</h1>
                  {unlimitedMembership.cancelAtPeriodEnd ? (
                    unlimitedMembership.scheduledRenewalDate ? (
                      <b
                        className={styles.smallLabel}
                        style={{ color: "#8F959E" }}
                      >
                        {t("In pausa")}
                        <img
                          src="/icons/pause_circle_grey.svg"
                          alt="pause icon"
                        />
                      </b>
                    ) : (
                      <b
                        className={styles.smallLabel}
                        style={{ color: "#8F959E" }}
                      >
                        {t("Cancellato")}
                        <img
                          src="/icons/cancel_circle_grey.svg"
                          alt="cancelled icon"
                        />
                      </b>
                    )
                  ) : (
                    <b
                      className={styles.smallLabel}
                      style={{ color: "#00BB71" }}
                    >
                      {t("Attivo")}
                      <img
                        src="/icons/check_circle_green.svg"
                        alt="check icon"
                      />
                    </b>
                  )}
                </div>
                <div className={styles.infoPack}>
                  <h4 className={styles.infoLabel}>{t("Valido fino al")}</h4>
                  <span className={styles.inputValue}>
                    {dayjs(unlimitedMembership.validUntil).format(
                      "DD / MM / YYYY",
                    )}
                  </span>
                </div>
                {(!unlimitedMembership.cancelAtPeriodEnd ||
                  unlimitedMembership.scheduledRenewalDate) && (
                  <div className={styles.infoPack}>
                    <h4 className={styles.infoLabel}>
                      {t("Prossimo rinnovo")}
                    </h4>
                    <span className={styles.inputValue}>
                      {dayjs(
                        unlimitedMembership.scheduledRenewalDate
                          ? unlimitedMembership.scheduledRenewalDate
                          : unlimitedMembership.validUntil,
                      ).format("DD / MM / YYYY")}
                    </span>
                  </div>
                )}
                <div style={{ marginTop: 24 }}>
                  <Button
                    className={styles.button}
                    type={"text"}
                    onClick={() => setUnlimitedEditModalOpen(true)}
                  >
                    {t("Gestisci abbonamento")}
                    <EditOutlined className={styles.buttonIcon} />
                  </Button>
                </div>
              </Col>
            </>
          ) : null}

          {!!me.firstName || !!me.lastName || !!me.email ? (
            <Col className={styles.infoContainer}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                }}
              >
                <h1 className={styles.infoTitle}>{t("Dati Personali")}</h1>
                <b className={styles.subEditBtn} onClick={toggleModal}>
                  {t("Modifica")}
                  <img
                    src="/icons/edit_small.svg"
                    alt="edit"
                    className={styles.editIcon}
                  />
                </b>
              </div>
              {me.firstName && (
                <div className={styles.infoPack}>
                  <h4 className={styles.infoLabel}>{t("Nome")}</h4>
                  <span className={styles.inputValue}>{me.firstName}</span>
                </div>
              )}
              {me.lastName && (
                <div className={styles.infoPack}>
                  <h4 className={styles.infoLabel}>{t("Cognome")}</h4>
                  <span className={styles.inputValue}>{me.lastName}</span>
                </div>
              )}
              {me.phone && (
                <div className={styles.infoPack}>
                  <h4 className={styles.infoLabel}>{t("Telefono")}</h4>
                  <span className={styles.inputValue}>{me.phone}</span>
                </div>
              )}
              {me.email && (
                <div className={styles.infoPack}>
                  <h4 className={styles.infoLabel}>{t("Email")}</h4>
                  <span className={styles.inputValue}>{me.email}</span>
                </div>
              )}
            </Col>
          ) : null}

          <Col className={styles.infoContainer}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
                marginBottom: 16,
              }}
            >
              <h1 className={styles.infoTitle}>{t("Indirizzo")}</h1>
              {!(
                unlimitedEnabled &&
                me.latestOrder &&
                unlimitedLimitAddressChanges
              ) ? (
                <b className={styles.subEditBtn} onClick={toggleModal}>
                  {t("Modifica")}
                  <img
                    src="/icons/edit_small.svg"
                    alt="edit"
                    className={styles.editIcon}
                  />
                </b>
              ) : null}
            </div>
            <span className={styles.inputValue}>
              {me.shippingInfo.address?.address1}
            </span>

            {me.shippingInfo.address?.province && (
              <div className={styles.infoPack}>
                <h4 className={styles.infoLabel}>{t("Città")}</h4>
                <span className={styles.inputValue}>
                  {me.shippingInfo.address?.city} (
                  {me.shippingInfo.address?.province})
                </span>
              </div>
            )}
            <div className={styles.infoPack}>
              <h4 className={styles.infoLabel}>{t("CAP")}</h4>
              <span className={styles.inputValue}>
                {me.shippingInfo.address?.zip}
              </span>
            </div>
          </Col>

          {!subsEnabled && me.isMember && (
            <Col className={styles.infoContainer}>
              <h4 className={styles.infoTitle}>
                Vuoi cambiare o interrompere la tua membership?
              </h4>
              <Button
                className={styles.button + " " + styles.link}
                style={{ marginTop: 8 }}
                onClick={() => {
                  trackUserAction(new ContactExpertCTASelected());
                  void sendRecentEvents();
                  window.open(supportUrl);
                }}
              >
                {t("Contatta il supporto clienti")}
              </Button>
            </Col>
          )}

          <Col className={styles.infoContainer} style={{ marginTop: 32 }}>
            <InvoiceForm />
            <Button
              className={styles.button}
              onClick={() => navigate("/profile/orders")}
            >
              {t("Ordini Passati")}
              <ProfileOutlined className={styles.buttonIcon} />
            </Button>
            <Button className={styles.button} type={"text"} onClick={_logout}>
              {t("Logout")}
              <LogoutOutlined className={styles.buttonIcon} />
            </Button>
            <AddressModal
              open={showModal}
              onOk={toggleModal}
              onCancel={toggleModal}
            />
          </Col>

          <Col className={styles.infoContainer}>
            <MarketingConsentModal
              open={marketingConsentModalOpen}
              setOpen={setMarketingConsentModalOpen}
            />
            <Button
              className={styles.button}
              type={"text"}
              onClick={() =>
                setMarketingConsentModalOpen(!marketingConsentModalOpen)
              }
            >
              {t("Aggiorna preferenze marketing")}
              <NotificationOutlined className={styles.buttonIcon} />
            </Button>
          </Col>
        </Col>
      </Row>
    </Space>
  );
};

export default Profile;
