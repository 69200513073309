import React, { useContext, useState } from "react";
import { Product } from "../common/product";
import { Card, Col, Row } from "antd";
import styles from "./styles.module.css";
import { CartContext } from "../Cart/CartContext";
import ProductModal from "./ProductModal";
import {
  ProductDetailsClosed,
  ProductDetailsOpened,
} from "../Networking/Metrics/UserActions/Products";
import useUATracker from "../Networking/Metrics/useUATracker";
import CartButtons from "../Shop/CartButtons";
import PriceBox from "./PriceBox";
import MeContext from "../AppSetup/MeContext";
import { useSettings } from "../Networking/useSettings";
import { useTranslation } from "react-i18next";
import Macros from "./Macros";
import { useImageTags } from "../Hooks/useImageTags";

interface ProductProps {
  product: Product;
  pricePerItem?: string;
  nonMembershipPricePerItem?: string;
  membershipInCart: boolean;
  inCart?: number;
  onAdd?: () => void;
  onRemove?: () => void;
  editable: boolean;
  maxCartSize?: number;
}

const ProductVerticalComponent: React.FC<ProductProps> = ({
  product,
  inCart,
  pricePerItem,
  membershipInCart,
  nonMembershipPricePerItem,
  onAdd,
  onRemove,
  editable,
  maxCartSize,
}) => {
  const [modalOpened, _setModalOpened] = useState(false);
  const { trackUserAction } = useUATracker();
  const meContext = useContext(MeContext);
  const isMember = meContext?.me.isMember;
  const { freemiumMode, subsEnabled, membership, hideRecapPrices } =
    useSettings();
  const { t } = useTranslation();

  // Prevent scrolling if details modal is opened
  if (modalOpened) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "unset";
  }

  const setModalOpened = (open: boolean) => {
    const event = open
      ? new ProductDetailsOpened({ productName: product.title })
      : new ProductDetailsClosed({ productName: product.title });
    trackUserAction(event);
    _setModalOpened(open);
  };

  const useMembershipPrice = isMember || membershipInCart;

  const toggleModal = () => {
    setModalOpened(!modalOpened);
  };

  const membershipExists = !!membership;

  const cart = useContext(CartContext);
  if (!cart && (onAdd || onRemove)) {
    throw new Error(
      "Cannot us ProductComponent in edit mode without a Cart Context",
    );
  }

  let canAdd = (inCart || 0) < product.inventoryQuantity;
  let cantAddReason: string | undefined;
  if (!canAdd && !editable) {
    cantAddReason = t("In arrivo! Scorte esaurite");
  }

  if (cart && editable) {
    if (!maxCartSize) {
      throw new Error("Product is editable but no max cart size was passed!");
    }
    const { totalItems } = cart;
    const cartFull = totalItems >= maxCartSize;
    if (cartFull) {
      canAdd = false;
      cantAddReason = t("Carrello Pieno!");
    }
    if (!canAdd && !cartFull) {
      cantAddReason = t("In arrivo! Scorte esaurite");
    }
  }

  const canRemove = !!inCart && inCart > 0;
  let cantRemoveReason: string | undefined;
  if (!canRemove) {
    cantRemoveReason = t("Il prodotto non è nel carrello");
  }

  const imageTags = useImageTags(product);

  return (
    <Col xs={12} md={6} className={styles.verticalCardContainer}>
      <Card
        key={product.id}
        className={
          (onAdd || onRemove) && canRemove
            ? styles.productCard + " " + styles.vertical + " " + styles.inCart
            : styles.productCard + " " + styles.vertical
        }
        bodyStyle={{
          padding: 0,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div
            className={styles.imageContainer}
            onClick={() => setModalOpened(!modalOpened)}
          >
            {imageTags.map((tag) => (
              <div
                key={tag.name}
                className={`${styles.imageTag} ${styles[tag.position]} ${tag.size ? styles[tag.size] : ""}`}
              >
                <div className={styles.imageWrapper}>
                  <img src={`/images/${tag.name}.png`} alt={`${tag.name}`} />
                </div>
              </div>
            ))}
            <img
              src={product.images[0].src}
              alt={product.title}
              className={styles.image}
            />
            <img
              src={"/icons/expand.svg"}
              alt={"Dettagli"}
              className={styles.expandIcon}
            />
          </div>

          <Row onClick={() => setModalOpened(!modalOpened)} justify="center">
            <div className={styles.title}>
              <span>{product.title}</span>
            </div>
          </Row>

          {product.macros && (
            <Macros
              product={product}
              modalOpened={modalOpened}
              setModalOpened={setModalOpened}
            />
          )}

          {pricePerItem && !subsEnabled && !hideRecapPrices && (
            <Row style={{ width: "100%" }} justify="center">
              {pricePerItem && (
                <PriceBox
                  price={pricePerItem}
                  highlight={useMembershipPrice}
                  strikethrough={membershipExists && !useMembershipPrice}
                  premium={!!freemiumMode}
                />
              )}
              {membershipExists &&
                freemiumMode &&
                nonMembershipPricePerItem && (
                  <PriceBox
                    price={nonMembershipPricePerItem}
                    highlight={!useMembershipPrice}
                    strikethrough={useMembershipPrice}
                    premium={false}
                  />
                )}
            </Row>
          )}
        </div>

        {!onAdd && !onRemove && (
          <Row
            justify="center"
            style={{ width: "100%" }}
            onClick={() => setModalOpened(!modalOpened)}
          >
            <div className={styles.detailsBtn}>{t("Dettagli")}</div>
            {inCart! > 0 && <div className={styles.qtyLabel}> x {inCart} </div>}
          </Row>
        )}

        {onAdd && onRemove && (
          <Row
            align="middle"
            justify="center"
            style={{ width: "100%", marginTop: "auto", marginBottom: 24 }}
          >
            <CartButtons product={product} isInCart={false} />
          </Row>
        )}

        <ProductModal
          product={product}
          inCart={inCart}
          onCancel={toggleModal}
          editable={editable}
          onAdd={onAdd}
          canAdd={canAdd}
          cantAddReason={cantAddReason}
          cantRemoveReason={cantRemoveReason}
          onRemove={onRemove}
          canRemove={canRemove}
          open={modalOpened}
        />
      </Card>
    </Col>
  );
};

export default ProductVerticalComponent;
